import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';

import { ContentTransformer } from '@crystallize/reactjs-components';
import { Link } from '@remix-run/react';

import CrystallizeImage from '~/components/atoms/CrystallizeImage';
import Heading from '~/components/atoms/Heading';
import { dateFormat } from '~/utils/date';

import { mergeSx } from '~/utils/mui';
import { BlogTileProps } from './types';

const BlogTile = ({ titleComponent = 'h2', hideLeadin, blog, sx, ...props }: BlogTileProps) => {
    const { hero, lead, title, name, createdAt, path, id } = blog;
    const theme = useTheme();
    const date = dateFormat(createdAt);
    return (
        <ButtonBase
            component={Link}
            to={path}
            {...props}
            sx={mergeSx(
                {
                    height: '100%',
                    '&:hover .blogTileBackground': {
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? theme.palette.background.darker
                                : theme.palette.background.lighter,
                    },
                    '&.Mui-focusVisible': {
                        boxShadow: theme.shadows[24],
                        borderRadius: theme.radius.all,
                    },
                },
                sx,
            )}
        >
            <Stack
                key={id}
                className="blogTileBackground"
                sx={{
                    height: '100%',
                    transition: 'background-color 0.3s ease-out',
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.background.dark : theme.palette.background.light,
                    borderRadius: theme.radius.allLarge,
                    overflow: 'hidden',
                }}
            >
                {hero?.content && (
                    <Box
                        sx={{
                            width: '100%',
                            padding: 0,
                            figure: {
                                margin: 0,
                                img: {
                                    width: '100%',
                                    height: 'auto',
                                },
                            },
                        }}
                    >
                        <CrystallizeImage
                            {...hero.content.firstImage}
                            showCaption={false}
                            aria-hidden="true"
                            alt=""
                            sizes="(max-width: 764px) 100vw, 33vw"
                        />
                    </Box>
                )}
                <Stack sx={{ p: 8, pb: 10 }}>
                    {date && (
                        <Typography
                            sx={{
                                mb: 2,
                                color:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.text.primary
                                        : theme.palette.text.muted,
                            }}
                            component="span"
                            variant="p3"
                        >
                            {date}
                        </Typography>
                    )}
                    <Heading component={titleComponent} variant="h5">
                        {title?.content?.text || name}
                    </Heading>
                    {!hideLeadin && (
                        <Typography
                            component="div"
                            variant="p1"
                            color="text.primary"
                            sx={{
                                '& p': { marginBlockEnd: '0' },
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '4',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {lead?.content && <ContentTransformer json={lead.content.json} />}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </ButtonBase>
    );
};

export default BlogTile;
